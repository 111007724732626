/**
 * It takes an endpoint and an object of options, and returns a URL with the options appended as query
 * parameters.
 * @param {string} enpoint - the url you want to hit
 * @param {object} options - the query parameters
 * @returns returns a string with the options appended as query parameters
 */
export default function urlBuilder(enpoint, options) {
  if (!options) {
    return `${enpoint}`;
  }

  const params = [];
  for (const [k, v] of Object.entries(options)) {
    if (v === null) {
      continue;
    }
    if (v === "") {
      continue;
    }
    if (v !== undefined) {
      params.push(`${k}=${v}`);
    }
  }

  if (params.length > 0) {
    return encodeURI(`${enpoint}?${params.join("&")}`);
  } else {
    return encodeURI(`${enpoint}`);
  }
}
