/* eslint-disable max-lines */

import { extractRole, isAllowedTo } from "@/assets/js/utils";
import {
  APIRequest,
  DownloadDTO,
  InstanceDTO,
  NavLink,
  PaginatedResponse,
  agencyid,
  paginatorSizesUnion,
  semver,
  uuid4,
} from "@/types/types";
import { INSTANCE_TYPE, PANEL, ROLE } from "~/assets/js/constants";
import { AgencyDTO, AgencySaveDTO, SelectedAgency } from "~/types/agency/agency";

import { ActionTree, MutationTree } from "vuex";
import { generateRandomKey } from "~/assets/js/stringUtils";
import ConfigService from "~/services/ConfigService";
import DownloadService from "~/services/DownloadService";
import NavigationTransformer from "~/transformers/NavigationTransformer";
import { User, UserDTO } from "~/types/user/user";

export const state = () => ({
  loggedUser: {} as User,
  cancelToken: null,
  panel: null,
  unverifiedUsername: null,
  mainInstanceId: "",
  mainInstance: {},
  selectedAgency: {} as SelectedAgency,
  agency: null,
  agencyRealName: null,
  taggedVersion: "",
  agentMinVersion: "",
  serviceType: "",
  federatedMainNav: [] as NavLink[],
  isWaitingForDownloads: null,
  downloads: [],
  instances: [],
  federatedView: false,
  singleAgencyUserAgency: null as AgencyDTO,
  mainNav: [] as NavLink[],
  APIRequestLimits: null as APIRequest,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  addNavLink(state, link: NavLink) {
    const subLinkIndex = state.mainNav.findIndex((nav) => nav.sublinks);
    if (
      subLinkIndex >= 0 &&
      !state.mainNav[subLinkIndex].sublinks.find((sublink) => sublink.textLink === link.textLink)
    ) {
      state.mainNav[subLinkIndex].sublinks.unshift(link);
    }
  },
  UPDATE_NAV_LINK(state, { savedAgency, id }) {
    // eslint-disable-next-line prefer-destructuring
    const { sublinks } = state.mainNav[1];

    const existingNavLink = sublinks.find(
      (sublink: { agency: { agencyId: agencyid } }) => sublink.agency.agencyId === id,
    );

    if (existingNavLink) {
      existingNavLink.agency.agentsConfig = savedAgency.agentsConfig;
    }
  },

  addFederatedNavLink(state, link) {
    state.mainNav.push(link);
  },

  removeNavLink(state, args: { index: number; subindex: number }) {
    const { index, subindex } = args;
    state.mainNav[index].sublinks.splice(subindex, 1);
  },

  removeFederatedNavLink(state, id) {
    const menuItemIndex = state.mainNav.findIndex((menuItem) => {
      return menuItem.link && menuItem.link === `/federated/${id}`;
    });
    const isFound = menuItemIndex >= 0;
    if (isFound) {
      state.mainNav.splice(menuItemIndex, 1);
    }
  },

  updateFederatedNavLink(state, instance) {
    const { id } = instance;
    const menuItemIndex = state.mainNav.findIndex((menuItem) => {
      return menuItem.link && menuItem.link === `/federated/${id}`;
    });
    const isFound = menuItemIndex >= 0;
    if (isFound) {
      const menuItemToUpdate = state.mainNav[menuItemIndex];
      menuItemToUpdate.textLink = instance.name;

      state.mainNav.splice(menuItemIndex, 1, menuItemToUpdate);
    }
  },

  removeAllNavLinks(state) {
    state.mainNav.forEach((link) => {
      if (link.sublinks) {
        link.sublinks.splice(0);
      }
    });
  },

  SET_SELECTED_AGENCY(state, agency) {
    const selectedAgency: SelectedAgency = {
      ...agency,
      agencyId: agency.id || agency.agencyId,
    };
    state.selectedAgency = selectedAgency;
    window.localStorage.setItem("selectedAgency", JSON.stringify(selectedAgency));
  },
  SET_FEDERATED_VIEW(state, value: boolean) {
    state.federatedView = value;
  },

  RESET_SELECTED_AGENCY(state) {
    state.selectedAgency = {} as SelectedAgency;
    window.localStorage.removeItem("selectedAgency");
  },

  RESET_FEDERATED_MAIN_NAV(state) {
    state.federatedMainNav = [];
  },

  SET_IS_WAITING_FOR_DOWNLOADS(state, value: boolean) {
    state.isWaitingForDownloads = value;
  },

  SET_DOWNLOADS(state, downloads) {
    state.downloads = downloads;
  },

  REMOVE_DOWNLOAD(state, id: uuid4) {
    state.downloads = state.downloads.filter((d: DownloadDTO) => d.id !== id);
  },

  setCancelToken(state, cancelToken) {
    state.cancelToken = cancelToken;
  },

  SET_LOGGED_USER(state, loggedUser) {
    state.loggedUser = loggedUser;
  },

  setServiceType(state, serviceType) {
    state.serviceType = serviceType;
  },

  setPageSize(state, pageSize) {
    state.loggedUser.config.pageSize = pageSize;
  },

  SET_PANEL(state, panel) {
    state.panel = panel;
  },

  setTaggedVersion(state, taggedVersion: semver) {
    state.taggedVersion = taggedVersion;
  },

  setAgentMinVersion(state, agentMinVersion: semver) {
    state.agentMinVersion = agentMinVersion;
  },

  UPDATE_MAIN_NAV_WITH_FEDERATED(state, navlinks: NavLink[]) {
    if (state?.loggedUser?.role !== ROLE.ADMIN) {
      return;
    }
    if (navlinks.length > 0) {
      state.federatedMainNav.push({ textLink: "menu.federatedAgencies", admin: true });
      navlinks.forEach((navLink, index) => {
        state.federatedMainNav[index + 1] = navLink;
      });
      state.mainNav = [...state.mainNav, ...state.federatedMainNav];
    }
  },

  SET_INSTANCES(state, instances) {
    state.instances = instances.collection;
  },

  SET_MAIN_INSTANCE_ID(state, instances: PaginatedResponse<InstanceDTO>) {
    const mainInstance = instances.collection.filter(
      (instance) => instance.type === INSTANCE_TYPE.MAIN,
    );
    if (mainInstance.length > 0) {
      state.mainInstanceId = mainInstance[0].id;
      // eslint-disable-next-line prefer-destructuring
      state.mainInstance = mainInstance[0];
    }
  },

  SET_UNVERIFIED_USERNAME(state, unverifiedUsername) {
    state.unverifiedUsername = unverifiedUsername;
  },

  SET_API_REQUEST_LIMITS(state, APIRequestLimits: APIRequest) {
    state.APIRequestLimits = APIRequestLimits;
  },

  SET_SINGLE_AGENCY_USER_AGENCY(state, agency: AgencyDTO) {
    state.singleAgencyUserAgency = agency;
  },
  SET_MAIN_NAV(state, mainNav) {
    state.mainNav = mainNav;
  },
};

function setupTelemetryNavLinks(mainNav, state, getters) {
  const hasTelemetry = state.selectedAgency?.agentsConfig?.telemetry;

  if (getters.getLoggedRole === ROLE.ADMIN) {
    mainNav.push({
      icon: "hashtag-light",
      textLink: "telemetry.label",
      link: "/telemetry",
    });
  } else if (getters.isSingleAgencyUser && getters.getLoggedRole === ROLE.USER && hasTelemetry) {
    mainNav.push({
      icon: "hashtag-light",
      textLink: "telemetry.label",
      link: `/agencies/${state.singleAgencyUserAgency?.id}/telemetry`,
    });
  }
}

export const actions: ActionTree<RootState, RootState> = {
  async setLoggedUser({ commit, dispatch, getters }, decodedAuthToken) {
    try {
      const { jti: userId, roles } = decodedAuthToken;

      let admin = false;
      if (roles.includes(ROLE.ADMIN)) {
        admin = true;
      }

      const role = extractRole(roles);

      const userResponse: UserDTO = await this.$axios.$get(`users/${userId}`);
      commit("SET_LOGGED_USER", { ...userResponse, admin, role });

      if (getters.isSingleAgencyUser && userResponse.agencies.length === 1) {
        commit("SET_SINGLE_AGENCY_USER_AGENCY", userResponse.agencies[0]);
        dispatch("setSelectedAgency", userResponse.agencies[0]);
      }

      dispatch("setMainNav");
    } catch {
      dispatch("authentication/logout", null, { root: true });
    }
  },

  setMainNav({ commit, state, getters }) {
    const mainNav = [
      {
        icon: "tachometter",
        textLink: "dashboard.label",
        link: "/",
        authorized: [ROLE.ADMIN, ROLE.MANAGER, ROLE.USER, ROLE.SERVICEDESK, ROLE.READONLY],
      },
      {
        sublinks: [],
      },
      { textLink: "menu.management" },
      {
        icon: "building",
        textLink: "agencies.label",
        link: "/agencies",
        iconClasses: "text-xl",
        authorized: (getters) => {
          if (
            !getters.isSingleAgencyUser &&
            isAllowedTo(
              [ROLE.ADMIN, ROLE.MANAGER, ROLE.USER, ROLE.SERVICEDESK, ROLE.READONLY],
              getters.getLoggedRole,
            )
          ) {
            return true;
          }

          if (getters.isSingleAgencyUser) {
            return false;
          }
        },
      },
      {
        icon: "user",
        textLink: "users.label",
        link: "/users",
        authorized: [ROLE.ADMIN, ROLE.MANAGER, ROLE.SERVICEDESK],
      },
      {
        icon: "computer",
        textLink: "computers.label",
        link: `/agencies/${state.singleAgencyUserAgency?.id}/computers`,
        authorized: (getters) => {
          if (
            getters.isSingleAgencyUser &&
            isAllowedTo([ROLE.USER, ROLE.READONLY], getters.getLoggedRole)
          ) {
            return true;
          }

          if (!getters.isSingleAgencyUser) {
            return false;
          }
        },
      },
      // Single agency vaccine navlink
      {
        icon: "syringe",
        textLink: "vaccines.label",
        link: `/agencies/${state.singleAgencyUserAgency?.id}/vaccines`,
        authorized: (getters) => {
          if (
            getters.getPanel === PANEL.USER &&
            isAllowedTo([ROLE.USER, ROLE.READONLY], getters.getLoggedRole) &&
            getters.isSingleAgencyUser
          ) {
            return true;
          }

          return false;
        },
      },
      // Non single agency vaccine navlink
      {
        icon: "syringe",
        textLink: "vaccines.label",
        link: "/vaccines",
        authorized: (getters) => {
          if (
            getters.getPanel === PANEL.USER &&
            isAllowedTo([ROLE.CLASSIFIED, ROLE.MANAGER], getters.getLoggedRole)
          ) {
            return true;
          }

          if (
            getters.getPanel === PANEL.ADMIN &&
            isAllowedTo([ROLE.ADMIN, ROLE.SERVICEDESK], getters.getLoggedRole)
          ) {
            return true;
          }

          return false;
        },
      },
      {
        icon: "alert",
        textLink: "alerts.label",
        link: "/alerts",
        authorized: [ROLE.ADMIN, ROLE.MANAGER, ROLE.USER, ROLE.SERVICEDESK, ROLE.READONLY],
      },
      {
        icon: "comment",
        textLink: "news.label",
        link: "/news",
      },
      {
        icon: "circle-nodes-light",
        textLink: "instances.label",
        link: "/federated",
        authorized: [ROLE.ADMIN],
      },
      {
        icon: "cert",
        textLink: "certs.label",
        link: "/certs",
        authorized: [ROLE.ADMIN],
      },
      {
        icon: "audit",
        textLink: "audit.label",
        link: "/audit",
        authorized: [ROLE.ADMIN],
      },
      {
        icon: "code-branch-light",
        textLink: "versions.label",
        link: "/version-control",
        authorized: [ROLE.ADMIN],
      },
    ];

    setupTelemetryNavLinks(mainNav, state, getters);

    commit("SET_MAIN_NAV", mainNav);
  },

  async updateMainNav({ commit, state }) {
    const data = await this.$axios.$get("instances");
    commit("SET_INSTANCES", data);
    commit("SET_MAIN_INSTANCE_ID", data);
    if (state.federatedMainNav.length === 0) {
      commit("UPDATE_MAIN_NAV_WITH_FEDERATED", NavigationTransformer.fetchFederated(data));
    }
  },
  setSelectedAgency({ commit }, agency: AgencyDTO) {
    commit("SET_SELECTED_AGENCY", agency);
  },

  resetSelectedAgency({ commit, getters }) {
    if (!getters.isSingleAgencyUser) {
      commit("RESET_SELECTED_AGENCY");
    }
  },

  addNavLink({ commit, dispatch, state }, args) {
    const { id: agencyId, name, instanceType, isSingleAgencyUser, agentsConfig } = args;

    dispatch("setSelectedAgency", { agencyId, name, instanceType, agentsConfig });

    commit("addNavLink", {
      icon: "ticket",
      textLink: name,
      link: isSingleAgencyUser ? `/agencies/${agencyId}/computers` : `/agencies/${agencyId}`,
      agency: state.selectedAgency,
      admin: true,
      uuid: generateRandomKey(),
      instanceType,
      closable: !isSingleAgencyUser,
      visible: !isSingleAgencyUser,
    });
  },
  updateNavLink({ commit }, { savedAgency, id }: { savedAgency: AgencySaveDTO; id: agencyid }) {
    commit("UPDATE_NAV_LINK", { savedAgency, id });
  },

  addFederatedNavLink({ commit }, instance: InstanceDTO) {
    commit("addFederatedNavLink", {
      icon: "network",
      textLink: instance.name,
      link: `/federated/${instance.id}`,
      authorized: [ROLE.ADMIN],
    });
  },

  updateFederatedNavLink({ commit }, instance: InstanceDTO) {
    commit("updateFederatedNavLink", instance);
  },

  removeFederatedNavLink({ commit }, id: uuid4) {
    commit("removeFederatedNavLink", id);
  },
  navigateToFaqs() {
    this.$router.push("/faqs");
  },
  navigateToConfig() {
    this.$router.push("/config");
  },

  async setDownloads({ commit }) {
    try {
      const response = await this.$axios.$get("tasks");
      commit("SET_DOWNLOADS", response);
    } catch (error) {
      this.$feedback.error(error.message);
    }
  },

  async downloadFile({ commit }, { id, filename }: { id: uuid4; filename: string }) {
    await DownloadService.downloadFile(this.$axios, id, filename, {
      commit,
      $feedback: this.$feedback,
    });
  },

  async downloadDocumentation() {
    await DownloadService.downloadDocumentation(this.$axios);
  },

  removeNavLink({ commit, dispatch }, args) {
    this.$router.push("/");
    dispatch("resetSelectedAgency");

    commit("removeNavLink", args);
  },

  removeAllNavLinks({ commit }) {
    commit("removeAllNavLinks");
  },

  renewCancelToken({ state, commit }) {
    if (state.cancelToken) {
      state.cancelToken.cancel();
    }
    commit("setCancelToken", this.$axios.CancelToken.source());
  },

  updateServiceType({ commit }, serviceType) {
    commit("setServiceType", serviceType);
  },

  async savePageSize({ state, commit }, pageSize: paginatorSizesUnion) {
    try {
      commit("setPageSize", pageSize);
      const { id, role, admin } = state.loggedUser;
      const res = await this.$axios.$patch(`users/${id}`, {
        config: {
          pageSize,
        },
      });
      commit("SET_LOGGED_USER", { ...res, role, admin });
    } catch (error) {
      throw new Error(error);
    }
  },

  async setUserNotifications({ state, commit }, notifications: boolean) {
    try {
      const { id, role, admin } = state.loggedUser;
      const res = await this.$axios.$patch(`users/${id}`, {
        config: {
          notifications,
        },
      });
      commit("SET_LOGGED_USER", { ...res, role, admin });
      this.$feedback.ok("feedback.update.config.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.config.error");
    }
  },

  async renewUserPassword({ dispatch }, args) {
    try {
      await this.$axios.post("/users/renewPassword", args);
      dispatch("authentication/cleanup", null, { root: true });
      this.$router.push("/login");
    } catch (error) {
      const text = `${error?.response?.data?.error}${error?.response?.data?.message}`;
      this.$feedback.error(text);
    }
  },

  async setEnv({ commit, state }) {
    if (!state.taggedVersion && !state.agentMinVersion && !state.panel) {
      try {
        const res = await this.$axios.$get(`version`);
        commit("setTaggedVersion", res.version.dot);
        commit("setAgentMinVersion", res.agent.version);
        commit("SET_PANEL", res.panel);
      } catch (error) {
        // Intentionally silenced exception
        // because when we are in login we don't want
        // to show custom error
        // throw new Error(error);
      }
    }
  },

  setFederatedView({ commit }, value: boolean) {
    commit("SET_FEDERATED_VIEW", value);
  },

  async getAPIRequestLimits({ commit }) {
    try {
      const { maxRequests, maxTokens } = await ConfigService.getAPIRequestLimits(this.$axios);
      commit("SET_API_REQUEST_LIMITS", { maxRequests, maxTokens });
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const getters = {
  getCancelToken(state) {
    return state.cancelToken;
  },
  getMainNav(state, getters) {
    const nav = state.mainNav.filter((link) => {
      return (getters.isAdminPanel && link.panel === PANEL.ADMIN) || link.panel === undefined;
    });

    return nav.filter((link) => {
      const { authorized, admin, superAdmin } = link;
      // Old authorization
      if (authorized === undefined) {
        return getters.isAdmin
          ? (getters.isSuperAdmin ? superAdmin : false) ||
              admin ||
              (admin === undefined && !superAdmin)
          : !admin && !superAdmin;
      } else if (typeof authorized === "function") {
        return authorized(getters);
      } else {
        return isAllowedTo(authorized, getters.getLoggedRole);
      }
    });
  },

  getLoggedUser(state) {
    return state.loggedUser;
  },
  getLoggedRole(state) {
    return state.loggedUser?.role;
  },
  getUser(state) {
    return state.user;
  },
  getServiceType(state) {
    return state.serviceType;
  },
  getVersion(state) {
    return state.version;
  },

  isAdminPanel(state) {
    return state.panel === PANEL.ADMIN;
  },

  isUserPanel(state) {
    return state.panel === PANEL.USER;
  },

  isSingleAgencyUser(state): boolean {
    if (
      state.loggedUser?.role === ROLE.ADMIN ||
      state.loggedUser?.role === ROLE.CLASSIFIED ||
      state.loggedUser?.role === ROLE.MANAGER ||
      state.loggedUser?.role === ROLE.SERVICEDESK
    ) {
      return false;
    }
    return (
      state.loggedUser?.agencies.length === 1 &&
      (state.loggedUser?.role === ROLE.USER || state.loggedUser?.role === ROLE.READONLY)
    );
  },
  isMultiAgencyUser(state): boolean {
    if (
      state.loggedUser?.role === ROLE.ADMIN ||
      state.loggedUser?.role === ROLE.CLASSIFIED ||
      state.loggedUser?.role === ROLE.MANAGER ||
      state.loggedUser?.role === ROLE.SERVICEDESK
    ) {
      return false;
    }
    return (
      state.loggedUser?.agencies.length > 1 &&
      (state.loggedUser?.role === ROLE.USER || state.loggedUser?.role === ROLE.READONLY)
    );
  },
  isRegularUser(state) {
    return state.loggedUser?.role === ROLE.USER;
  },
  isAdminUser(state) {
    return state.loggedUser?.role === ROLE.ADMIN;
  },
  isManagerUser(state) {
    return state.loggedUser?.role === ROLE.MANAGER;
  },
  isAdmin(state) {
    return state.loggedUser?.role === ROLE.ADMIN || state.loggedUser?.role === ROLE.MANAGER;
  },
  isSuperAdmin(state) {
    return state.loggedUser?.role === ROLE.ADMIN;
  },
  isInAgency(state) {
    return state.selectedAgency?.agencyId !== undefined;
  },
  getSelectedAgency(state) {
    return state.selectedAgency;
  },
  getAgencyNotify(state) {
    return state.agencyNotify;
  },
  getAgencyAutoapply(state) {
    return state.agencyAutoapply;
  },
  showAgencyConfig(state) {
    return state.showAgencyConfig;
  },
  getPanel(state) {
    return state.panel;
  },
  getPageSize(state) {
    return state.loggedUser?.config.pageSize;
  },
  getMainInstanceId(state) {
    return state.mainInstanceId;
  },
  getMainInstanceType(state) {
    return state.mainInstance.type;
  },
  isMainInstanceAbleToEditVaccines(state) {
    return state.mainInstance.manageVaccines;
  },
  areTabsOpen(state) {
    return state.mainNav[1].sublinks.length > 0;
  },
  arePendingDownloads(state) {
    return state.downloads?.length > 0;
  },
  areDownloadsCompleted(state) {
    return state.downloads.every((download) => download.complete === true);
  },
  isFederatedView(state) {
    return state.federatedView;
  },
  getSingleAgencyUserAgency(state) {
    return state.singleAgencyUserAgency;
  },
};
