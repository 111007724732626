import type { ActionTree, MutationTree } from "vuex";
import { PaginatedResponse, RequestConfigParams, uuid4 } from "~/types/types";
import {
  ADD_NEWS_ITEM,
  DELETE_NEWS_ITEM,
  SET_EDITING_NEWS_ITEM,
  SET_NEWS_ITEM,
  SET_NEWS_ITEMS,
} from "./newsMutationTypes";

import urlBuilder from "~/assets/js/urlBuilder";
import { NewsItemDTO } from "~/types/newsItem/newsItem";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  newsItems: null as PaginatedResponse<NewsItemDTO>,
  loading: false,
  editingNewsItem: null as NewsItemDTO,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [ADD_NEWS_ITEM](state, newsItem: NewsItemDTO) {
    state.newsItems.collection.unshift(newsItem);
  },

  [DELETE_NEWS_ITEM](state, id: uuid4) {
    const index = state.newsItems.collection.findIndex((newsItem) => newsItem.id === id);
    const isNewsItemFound = index >= 0;

    if (isNewsItemFound) {
      state.newsItems.collection.splice(index, 1);
    }
  },

  [SET_NEWS_ITEM](state, updatedNewsItem) {
    const index = state.newsItems.collection.findIndex(
      (config) => config.id === updatedNewsItem.id,
    );

    if (index >= 0) {
      state.newsItems.collection.splice(index, 1, updatedNewsItem);
    }
  },

  [SET_NEWS_ITEMS](state: RootState, newsItems: PaginatedResponse<NewsItemDTO>) {
    state.newsItems = newsItems;
  },

  [SET_EDITING_NEWS_ITEM](state, editingNewsItem: NewsItemDTO) {
    state.editingNewsItem = editingNewsItem;
  },

  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit }, requestParams: RequestConfigParams) {
    commit(SET_LOADING, true);
    try {
      await dispatch("setNewsItems", requestParams);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  setLoading({ commit }, loading) {
    commit(SET_LOADING, loading);
  },

  async setNewsItems({ commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { page, sortBy, orderBy, filter } = requestParams;

    try {
      const size = rootGetters["application/getPageSize"];

      const apiUrl = urlBuilder(`news`, {
        size,
        page,
        sortBy,
        orderBy,
        filter,
      });

      const newsItems = await this.$axios.$get<PaginatedResponse<NewsItemDTO>>(apiUrl);

      commit(SET_NEWS_ITEMS, newsItems);
    } catch (error) {
      throw new Error(error);
    }
  },

  async addNewsItem({ commit }, newsItem: NewsItemDTO) {
    try {
      const createdNewsItem = await this.$axios.$post<NewsItemDTO>("news", newsItem);

      commit(ADD_NEWS_ITEM, createdNewsItem);
      this.$feedback.ok("feedback.create.news.ok");
    } catch (error) {
      const { error: prefix, message } = error.response.data;
      this.$feedback.error(`${prefix}${message}`);
    }
  },

  async setNewsItem({ commit }, { savedNewsItem, id }: { savedNewsItem: NewsItemDTO; id: uuid4 }) {
    const { headline, body } = savedNewsItem;
    try {
      const newsItem = await this.$axios.$patch<NewsItemDTO>(`news/${id}`, { id, headline, body });
      commit(SET_NEWS_ITEM, newsItem);
      this.$feedback.ok("feedback.update.news.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.news.error");
    }
  },

  async deleteNewsItem({ commit }, id: uuid4) {
    try {
      await this.$axios.delete(`news/${id}`);
      commit(DELETE_NEWS_ITEM, id);
      this.$feedback.ok("feedback.delete.news.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.news.error");
    }
  },

  async fetchNewsItem({ commit }, id: uuid4) {
    try {
      const editingNewsItem = await this.$axios.$get<NewsItemDTO>(`news/${id}`);
      commit(SET_EDITING_NEWS_ITEM, editingNewsItem);
    } catch (error) {
      throw new Error(error);
    }
  },
};
