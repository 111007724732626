import { isTokenExpired } from "~/assets/js/isTokenExpired";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default async function ({ $axios, app, store }, redirect) {
  /* This is a middleware that intercepts the request and adds the Authorization header to the request. */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  await $axios.onRequest((config) => {
    const token = store.getters["authentication/getAuthToken"];
    if (token) {
      $axios.setHeader("Authorization", token);
    }
  });

  /* This is a middleware that intercepts the error response and checks if the error is a 403. If it is,
it checks if the token is expired. If it is, it will refresh the token and then retry the request. */
  await $axios.onError(async (error) => {
    const code = parseInt(error.response && error.response.status);

    const maxRetries = 3;

    // Initialize retry count if it does not exist
    if (!error.config.__retryCount) {
      error.config.__retryCount = 0;
    }

    if (code === 403) {
      const token = store.getters["authentication/getAuthToken"];
      if (isTokenExpired(token)) {
        // Check if max retries have been reached
        if (error.config.__retryCount >= maxRetries) {
          return Promise.reject(error);
        }

        // Increment the retry count
        error.config.__retryCount += 1;

        await store.dispatch("authentication/refreshAuthToken");
        const newToken = store.getters["authentication/getAuthToken"];

        error.config.headers.Authorization = newToken;
        return $axios.request(error.config);
      }
    }
    if (code === 418) {
      store.dispatch("authentication/cleanup");
      app.router.push("/");
    }

    return Promise.reject(error);
  });
}

