import { differenceInMilliseconds, fromUnixTime } from "date-fns";

import jwtDecode from "jwt-decode";
import { AUTH_STATE } from "~/assets/js/constants";

export const state = () => ({
  authToken: null,
  refreshToken: null,
  error: false,
  expiration: null,
  waiting: false,
  isPasswordValid: null,
  inactivityTimeoutId: null,
  QRCode: null,
});

export const mutations = {
  SET_AUTH_TOKEN(state, authToken) {
    if (authToken) {
      localStorage.setItem("authToken", authToken);
    } else {
      localStorage.removeItem("authToken");
    }
    state.authToken = authToken;
  },

  SET_REFRESH_TOKEN(state, refreshToken) {
    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    } else {
      localStorage.removeItem("refreshToken");
    }
    state.refreshToken = refreshToken;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_WAITING(state, isWaiting) {
    state.waiting = isWaiting;
  },
  SET_IS_PASSWORD_VALID(state, isPasswordValid) {
    state.isPasswordValid = isPasswordValid;
  },
  SET_INACTIVITY_TIMEOUT_ID(state, inactivityTimeoutId) {
    state.inactivityTimeoutId = inactivityTimeoutId;
  },
  SET_QR_CODE(state, QRCode) {
    state.QRCode = QRCode;
  },
};

export const actions = {
  async nuxtClientInit({ dispatch }, router) {
    const authToken = state.authToken ? state.authToken : localStorage.getItem("authToken");
    const refreshToken = state.refreshToken
      ? state.refreshToken
      : localStorage.getItem("refreshToken");

    if (authToken && refreshToken) {
      await dispatch("manageAuthentication", {
        router,
        authToken,
        refreshToken,
      });
    }
  },

  /* A function that is called when the user logs out. It is responsible for removing the
  authentication token and refresh token from the state and for clearing the inactivity timeout. */
  async logout({ dispatch }, isInactive = false) {
    try {
      if (!isInactive) {
        await this.$axios.post("/auth/logout");
      }
    } finally {
      dispatch("cleanup");
      this.$router.push("/login");
    }
  },

  cleanup({ commit }) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    commit("SET_AUTH_TOKEN", null);
    commit("SET_REFRESH_TOKEN", null);
    commit("application/SET_LOGGED_USER", null, { root: true });
    commit("application/setServiceType", null, { root: true });
    commit("application/RESET_SELECTED_AGENCY", null, { root: true });
    commit("application/SET_DOWNLOADS", [], { root: true });
    commit("application/removeAllNavLinks", {}, { root: true });
    commit("application/RESET_FEDERATED_MAIN_NAV", {}, { root: true });
    commit("SET_IS_PASSWORD_VALID", null);
    commit("SET_ERROR", false);
  },

  async login({ state, dispatch, rootGetters, commit }, args) {
    dispatch("setError", false);
    if (!state.waiting) {
      dispatch("setWaiting", true);

      try {
        const res = await this.$axios.post("login", args);
        const authToken = res.data.token;
        const { refreshToken } = res.data;

        await dispatch("manageAuthentication", {
          router: this.$router,
          authToken,
          refreshToken,
        });
        const loggedUser = rootGetters["application/getLoggedUser"];
        if (loggedUser.config.enabled2fa) {
          this.$router.push("/code");
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        if (error.response.data.message === "feedback.api.error.credentialsExpired") {
          commit("application/SET_UNVERIFIED_USERNAME", args.username, { root: true });
          this.$router.push("/renew");
        } else {
          dispatch("setWaiting", false);
          dispatch("setError", true);
        }
      }
    }
  },

  /* A function that is called when the user logs in. It is responsible for setting the authentication
  token and refresh token in the state and for setting the inactivity timeout. */
  async manageAuthentication({ commit, dispatch }, args) {
    const { authToken, refreshToken } = args;
    if (authToken) {
      const decodedRefreshToken = jwtDecode(refreshToken);
      const decodedAuthToken = jwtDecode(authToken);

      await commit("SET_AUTH_TOKEN", authToken);
      await commit("SET_REFRESH_TOKEN", refreshToken);

      this.$axios.setHeader("Authorization", authToken);

      dispatch("setInactivityTimeout", decodedRefreshToken);

      await dispatch("application/setLoggedUser", decodedAuthToken, { root: true });
    }
  },

  async refreshAuthToken({ dispatch, state }) {
    dispatch("setError", false);
    try {
      const res = await this.$axios.$post("auth/refresh", {
        refreshToken: state.refreshToken,
      });
      const { token: authToken, refreshToken } = res;
      dispatch("manageAuthentication", { authToken, refreshToken });
    } catch (error) {
      dispatch("setError", true);
      dispatch("logout");
    }
  },

  clearInactivityTimeout({ commit, state }) {
    if (state.inactivityTimeoutId) {
      clearTimeout(state.inactivityTimeoutId);
      commit("SET_INACTIVITY_TIMEOUT_ID", null);
    }
  },

  setInactivityTimeout({ commit, dispatch }, decodedRefreshToken) {
    dispatch("clearInactivityTimeout");

    const expiration = fromUnixTime(decodedRefreshToken.exp);
    const now = new Date();
    const time = differenceInMilliseconds(expiration, now);

    if (time > 0) {
      const inactivityTimeoutId = setTimeout(() => {
        dispatch("logout", true);
      }, time);
      commit("SET_INACTIVITY_TIMEOUT_ID", inactivityTimeoutId);
    } else {
      dispatch("setWaiting", false);
      dispatch("logout");
    }
  },

  async getQR({ commit, state }) {
    const twoFAState = jwtDecode(state.authToken).roles;
    if (twoFAState === AUTH_STATE.ROLE_PRE_AUTH_SECRET) {
      const qr = await this.$axios.$get("auth/code");
      commit("SET_QR_CODE", qr);
    }
  },

  async resetQRCode(context, userId) {
    try {
      await this.$axios.$patch(`/users/${userId}/reset2fa`);
      this.$feedback.ok("feedback.twoFA.reset.ok");
    } catch (error) {
      this.$feedback.error("feedback.twoFA.reset.error");
    }
  },

  async sendPincode({ dispatch }, pincode) {
    dispatch("setError", false);
    try {
      dispatch("setWaiting", true);
      const { token: authToken, refreshToken } = await this.$axios.$get(`auth/code/${pincode}`);

      dispatch("manageAuthentication", { authToken, refreshToken });
      this.$router.push("/");
    } catch (error) {
      dispatch("setWaiting", false);
      dispatch("setError", true);
      this.$router.push("/code");
    }
  },

  setError({ commit }, isError) {
    commit("SET_ERROR", isError);
  },
  setWaiting({ commit }, isWaiting) {
    commit("SET_WAITING", isWaiting);
  },
};

export const getters = {
  getAuthToken(state) {
    return state.authToken;
  },
  getRefreshToken(state) {
    return state.refreshToken;
  },
  isAuthorizated(state) {
    return state.authToken !== null;
  },
  isError(state) {
    return state.error;
  },
  isWaiting(state) {
    return state.waiting;
  },
  isPasswordValid(state) {
    return state.isPasswordValid;
  },
  getTwoFAState(state) {
    return jwtDecode(state.authToken).roles;
  },
  getQRCode(state) {
    return state.QRCode;
  },
};
